export default [
  {
      "globalSportId": 1,
      "name": "Baseball",
      "shortname": "baseball"
  },
  {
      "globalSportId": 2,
      "name": "Softball",
      "shortname": "softball"
  },
  {
      "globalSportId": 3,
      "name": "Men's Basketball",
      "shortname": "mbball"
  },
  {
      "globalSportId": 4,
      "name": "Women's Basketball",
      "shortname": "wbball"
  },
  {
      "globalSportId": 5,
      "name": "Football",
      "shortname": "football"
  },
  {
      "globalSportId": 6,
      "name": "Field Hockey",
      "shortname": "fhockey"
  },
  {
      "globalSportId": 7,
      "name": "Men's Soccer",
      "shortname": "msoc"
  },
  {
      "globalSportId": 8,
      "name": "Women's Soccer",
      "shortname": "wsoc"
  },
  {
      "globalSportId": 9,
      "name": "Men's Golf",
      "shortname": "mgolf"
  },
  {
      "globalSportId": 10,
      "name": "Women's Golf",
      "shortname": "wgolf"
  },
  {
      "globalSportId": 11,
      "name": "Men's Cross Country",
      "shortname": "mcross"
  },
  {
      "globalSportId": 12,
      "name": "Women's Cross Country",
      "shortname": "wcross"
  },
  {
      "globalSportId": 13,
      "name": "Men's Ice Hockey",
      "shortname": "mhockey"
  },
  {
      "globalSportId": 14,
      "name": "Women's Ice Hockey",
      "shortname": "whockey"
  },
  {
      "globalSportId": 15,
      "name": "Men's Lacrosse",
      "shortname": "mlax"
  },
  {
      "globalSportId": 16,
      "name": "Women's Lacrosse",
      "shortname": "wlax"
  },
  {
      "globalSportId": 17,
      "name": "Men's Polo",
      "shortname": "mpolo"
  },
  {
      "globalSportId": 18,
      "name": "Women's Polo",
      "shortname": "wpolo"
  },
  {
      "globalSportId": 19,
      "name": "Men's Tennis",
      "shortname": "mten"
  },
  {
      "globalSportId": 20,
      "name": "Women's Tennis",
      "shortname": "wten"
  },
  {
      "globalSportId": 21,
      "name": "Men's Volleyball",
      "shortname": "mvball"
  },
  {
      "globalSportId": 22,
      "name": "Women's Volleyball",
      "shortname": "wvball"
  },
  {
      "globalSportId": 23,
      "name": "Men's Swimming and Diving",
      "shortname": "mswim"
  },
  {
      "globalSportId": 24,
      "name": "Women's Swimming and Diving",
      "shortname": "wswim"
  },
  {
      "globalSportId": 25,
      "name": "Men's Track and Field",
      "shortname": "mtrack"
  },
  {
      "globalSportId": 26,
      "name": "Women's Track and Field",
      "shortname": "wtrack"
  },
  {
      "globalSportId": 27,
      "name": "Track and Field",
      "shortname": "track"
  },
  {
      "globalSportId": 28,
      "name": "Swimming and Diving",
      "shortname": "swim"
  },
  {
      "globalSportId": 29,
      "name": "Cross Country",
      "shortname": "cross"
  },
  {
      "globalSportId": 30,
      "name": "Wrestling",
      "shortname": "wrestling"
  },
  {
      "globalSportId": 31,
      "name": "Women's Wrestling",
      "shortname": "wwrestling"
  },
  {
      "globalSportId": 32,
      "name": "Women's Gymnastics",
      "shortname": "wgym"
  },
  {
      "globalSportId": 33,
      "name": "Men's Gymnastics",
      "shortname": "mgym"
  },
  {
      "globalSportId": 34,
      "name": "Men's Squash",
      "shortname": "msquash"
  },
  {
      "globalSportId": 35,
      "name": "Women's Squash",
      "shortname": "wsquash"
  },
  {
      "globalSportId": 36,
      "name": "Wheelchair Basketball",
      "shortname": "wcbball"
  },
  {
      "globalSportId": 37,
      "name": "General",
      "shortname": "general"
  },
  {
      "globalSportId": 38,
      "name": "Cheerleading",
      "shortname": "cheer"
  },
  {
      "globalSportId": 39,
      "name": "Dance Team",
      "shortname": "dance"
  },
  {
      "globalSportId": 40,
      "name": "Men's Crew",
      "shortname": "mcrew"
  },
  {
      "globalSportId": 41,
      "name": "Women's Crew",
      "shortname": "wcrew"
  },
  {
      "globalSportId": 42,
      "name": "Men's Rowing",
      "shortname": "mrow"
  },
  {
      "globalSportId": 43,
      "name": "Women's Rowing",
      "shortname": "wrow"
  },
  {
      "globalSportId": 44,
      "name": "Men's Sailing",
      "shortname": "msail"
  },
  {
      "globalSportId": 45,
      "name": "Women's Sailing",
      "shortname": "wsail"
  },
  {
      "globalSportId": 46,
      "name": "Sailing",
      "shortname": "sail"
  },
  {
      "globalSportId": 47,
      "name": "Men's Indoor Track",
      "shortname": "mitrack"
  },
  {
      "globalSportId": 48,
      "name": "Women's Indoor Track",
      "shortname": "witrack"
  },
  {
      "globalSportId": 49,
      "name": "Indoor Track",
      "shortname": "itrack"
  },
  {
      "globalSportId": 50,
      "name": "Outdoor Track",
      "shortname": "otrack"
  },
  {
      "globalSportId": 51,
      "name": "Golf",
      "shortname": "golf"
  },
  {
      "globalSportId": 52,
      "name": "Tennis",
      "shortname": "tennis"
  },
  {
      "globalSportId": 53,
      "name": "Rifle",
      "shortname": "rifle"
  },
  {
      "globalSportId": 54,
      "name": "Men's Fencing",
      "shortname": "mfence"
  },
  {
      "globalSportId": 55,
      "name": "Women's Fencing",
      "shortname": "wfence"
  },
  {
      "globalSportId": 56,
      "name": "Badminton",
      "shortname": "badminton"
  },
  {
      "globalSportId": 57,
      "name": "Curling",
      "shortname": "curling"
  },
  {
      "globalSportId": 58,
      "name": "FastPitch",
      "shortname": "fastpitch"
  },
  {
      "globalSportId": 59,
      "name": "Fencing",
      "shortname": "fencing"
  },
  {
      "globalSportId": 60,
      "name": "Figure Skating",
      "shortname": "figureskate"
  },
  {
      "globalSportId": 61,
      "name": "Mountain Biking",
      "shortname": "moutainbike"
  },
  {
      "globalSportId": 62,
      "name": "Nordic Skiing",
      "shortname": "nordic"
  },
  {
      "globalSportId": 63,
      "name": "Rowing",
      "shortname": "row"
  },
  {
      "globalSportId": 64,
      "name": "Women's Synchronized Swimming",
      "shortname": "wsswim"
  },
  {
      "globalSportId": 65,
      "name": "Men's Rifle",
      "shortname": "mrifle"
  },
  {
      "globalSportId": 66,
      "name": "Women's Rifle",
      "shortname": "wrifle"
  },
  {
      "globalSportId": 67,
      "name": "Men's Bowling",
      "shortname": "mbowl"
  },
  {
      "globalSportId": 68,
      "name": "Women's Bowling",
      "shortname": "wbowl"
  },
  {
      "globalSportId": 69,
      "name": "Men's Skiing",
      "shortname": "mski"
  },
  {
      "globalSportId": 70,
      "name": "Women's Skiing",
      "shortname": "wski"
  },
  {
      "globalSportId": 71,
      "name": "Equestrian",
      "shortname": "equest"
  },
  {
      "globalSportId": 72,
      "name": "Men's Water Polo",
      "shortname": "mwpolo"
  },
  {
      "globalSportId": 73,
      "name": "Women's Water Polo",
      "shortname": "wwpolo"
  },
  {
      "globalSportId": 74,
      "name": "Alpine Skiing",
      "shortname": "alpine"
  },
  {
      "globalSportId": 75,
      "name": "Rodeo",
      "shortname": "rodeo"
  },
  {
      "globalSportId": 76,
      "name": "Men's Rodeo",
      "shortname": "mrodeo"
  },
  {
      "globalSportId": 77,
      "name": "Women's Rodeo",
      "shortname": "wrodeo"
  },
  {
      "globalSportId": 78,
      "name": "Club Hockey",
      "shortname": "chockey"
  },
  {
      "globalSportId": 79,
      "name": "SAAC",
      "shortname": "saac"
  },
  {
      "globalSportId": 80,
      "name": "Men's Badminton",
      "shortname": "mbad"
  },
  {
      "globalSportId": 81,
      "name": "Women's Badminton",
      "shortname": "wbad"
  },
  {
      "globalSportId": 82,
      "name": "Men's Curling",
      "shortname": "mcurl"
  },
  {
      "globalSportId": 83,
      "name": "Women's Curling",
      "shortname": "wcurl"
  },
  {
      "globalSportId": 84,
      "name": "Men's Nordic Skiing",
      "shortname": "mnski"
  },
  {
      "globalSportId": 85,
      "name": "Men's Rugby",
      "shortname": "mrugby"
  },
  {
      "globalSportId": 86,
      "name": "Women's Rugby",
      "shortname": "wrugby"
  },
  {
      "globalSportId": 87,
      "name": "Men's Badmiton",
      "shortname": "mbad"
  },
  {
      "globalSportId": 88,
      "name": "Women's Badmiton",
      "shortname": "wbad"
  },
  {
      "globalSportId": 89,
      "name": "Cricket",
      "shortname": "cricket"
  },
  {
      "globalSportId": 90,
      "name": "Cycling",
      "shortname": "cycling"
  },
  {
      "globalSportId": 91,
      "name": "Fastpitch",
      "shortname": "fastp"
  },
  {
      "globalSportId": 92,
      "name": "Women's Flag Football",
      "shortname": "wflag"
  },
  {
      "globalSportId": 93,
      "name": "Synchronized Swimming",
      "shortname": "Syncs"
  },
  {
      "globalSportId": 94,
      "name": "Sprint Football",
      "shortname": "sfootball"
  },
  {
      "globalSportId": 95,
      "name": "JV Men's Basketball",
      "shortname": "jvmbball"
  },
  {
      "globalSportId": 96,
      "name": "JV Women's Basketball",
      "shortname": "jvwbball"
  },
  {
      "globalSportId": 97,
      "name": "Women's Beach Volleyball",
      "shortname": "wbvball"
  },
  {
      "globalSportId": 98,
      "name": "Triathlon",
      "shortname": "triathlon"
  },
  {
      "globalSportId": 99,
      "name": "Acrobatics & Tumbling",
      "shortname": "acrobaticstumbling"
  },
  {
      "globalSportId": 100,
      "name": "Women's Lightweight Rowing",
      "shortname": "lightrowing"
  },
  {
      "globalSportId": 101,
      "name": "JV Baseball",
      "shortname": "jvbaseball"
  },
  {
      "globalSportId": 102,
      "name": "JV Softball",
      "shortname": "jvsoftball"
  },
  {
      "globalSportId": 103,
      "name": "JV Men's Soccer",
      "shortname": "jvmsoc"
  },
  {
      "globalSportId": 104,
      "name": "JV Women's Soccer",
      "shortname": "jvwsoc"
  },
  {
      "globalSportId": 105,
      "name": "JV Women's Volleyball",
      "shortname": "jvwvball"
  },
  {
      "globalSportId": 106,
      "name": "JV Men's Tennis",
      "shortname": "jvmten"
  },
  {
      "globalSportId": 107,
      "name": "JV Women's Tennis",
      "shortname": "jvwten"
  },
  {
      "globalSportId": 108,
      "name": "D3 Baseball",
      "shortname": "d3baseball"
  },
  {
      "globalSportId": 109,
      "name": "D3 Men's Basketball",
      "shortname": "d3mbball"
  },
  {
      "globalSportId": 110,
      "name": "D3 Women's Basketball",
      "shortname": "d3wbball"
  },
  {
      "globalSportId": 111,
      "name": "D3 Women's Volleyball",
      "shortname": "d3wvball"
  },
  {
      "globalSportId": 112,
      "name": "Co-ed Squash",
      "shortname": "csquash"
  },
  {
      "globalSportId": 113,
      "name": "Synchronized Skating",
      "shortname": "synchronized"
  },
  {
      "globalSportId": 114,
      "name": "Men's Field hockey",
      "shortname": "mfhockey"
  },
  {
      "globalSportId": 115,
      "name": "eSports",
      "shortname": "esports"
  },
  {
      "globalSportId": 116,
      "name": "JV Football",
      "shortname": "jvfootball"
  }
]